export const controlStyles = {
  base: 'rounded-md border border-gray-300  hover:cursor-pointer text-sm',
  focus: '',
  nonFocus: 'border-gray-300 hover:border-gray-400',
  error: 'border-2 border-dashed border-red-500 ',
  focusWithError: 'border-2 border-dashed border-primary ',
  disabledInputStyles: 'bg-gray-50 text-gray-500 ring-gray-200',
};
export const placeholderStyles = 'text-sm text-gray-400 pl-1 py-0.5';
export const selectInputStyles = 'pl-1 py-0.5 ';

export const valueContainerStyles = 'p-1 gap-1';
export const singleValueStyles = 'leading-7 ml-1  ';
export const multiValueStyles = 'bg-gray-100 rounded items-center pl-2 pr-1 gap-1.5 text-sm';
export const multiValueLabelStyles = 'leading-6 py-0.5';
export const multiValueRemoveStyles =
  'border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md';
export const indicatorsContainerStyles = 'p-1 gap-1';
export const indicatorsContainerWithoutBorder =
  'p-1 gap-1 bg-gray-100 rounded items-center  text-sm';
export const clearIndicatorStyles =
  'text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800';
export const indicatorSeparatorStyles = 'bg-gray-300';
export const dropdownIndicatorStyles =
  'p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black text-sm';
export const menuStyles = 'p-1 mt-2 border border-gray-200 bg-white rounded-lg text-sm';
export const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-gray-500 text-sm';
export const optionStyles = {
  base: 'hover:cursor-pointer px-3 py-2 rounded text-sm ',
  focus: 'bg-gray-100 active:bg-gray-200',
  selected: "after:content-['✔'] after:ml-2 after:text-primary text-gray-500 ",
};
export const noOptionsMessageStyles =
  'text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm text-sm';
