interface BadgeProps {
  state?: boolean;
  textStyles?: string;
  containerStyles?: string;
  status?: string;
}

const Badge = ({ state, textStyles, containerStyles, status }: BadgeProps) => (
  <span
    className={`inline-flex items-center rounded-lg border-0 ${
      state
        ? `${
            containerStyles ||
            'bg-badge_success text-badge_success_inner ring-badge_success_inner'
          }`
        : `${
            containerStyles ||
            'bg-badge_danger text-badge_danger_inner ring-badge_danger_inner'
          }`
    } px-3 py-1   ${textStyles || 'font-worksans_medium'} text-md ring-1 `}
  >
    {status || (state ? 'Active' : 'Inactive')}
  </span>
);

export default Badge;
