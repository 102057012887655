import Cookies from 'js-cookie';
import { APPTypes } from 'Models/IPlatformManagement';

const useCookies = () => {
  // Session management
  const token = Cookies.get('token');
  const userName = Cookies.get('uname');
  const error_type = Cookies.get('error_type');
  const error_msg = Cookies.get('error_msg');
  const email = Cookies.get('email');

  const removeErrors = () => {
    Cookies.remove('error_type');
    Cookies.remove('error_msg');
  };

  // Account onboarding management
  const oauth_state = Cookies.get('oauth_state');
  const job_id = Cookies.get('oauth_job_id');
  const account_id = Cookies.get('oauth_account_id');
  const app = Cookies.get('oauth_app') as APPTypes;

  const removePlatformManagementCookies = () => {
    Cookies.remove('oauth_state');
    Cookies.remove('oauth_job_id');
    Cookies.remove('oauth_account_id');
    Cookies.remove('oauth_app');
  };

  return {
    token,
    userName,
    error_msg,
    error_type,
    removeErrors,
    email,
    removePlatformManagementCookies,
    oauth_state,
    job_id,
    account_id,
    app,
  };
};

export default useCookies;
