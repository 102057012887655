/* eslint-disable max-lines */
import Badge from 'components/Badge';
import Button from 'components/Button';
import { Columns } from 'components/DataTable/Columns';
import VirtualizedDataTable from 'components/DataTable/VirtualizedDataTable';
import { Account, APPTypes } from 'Models/IPlatformManagement';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AddAccountsBody,
  getAddedAccounts,
  getOauthAccounts,
  onAddAccounts,
} from 'api/requests/deploybot';
import Modal from 'components/Modal';
import InformationIcon from 'assets/svg/InformationIcon';
import MultiToolTip from 'components/MultiToolTip';
import EntityIDGuide from '../../assets/images/accountOnboarding/entity_id_guide.png';
import AccountAddedIcon from '../../assets/images/accountOnboarding/account_added_icon.svg';
import { useReportsContext } from 'context/ReportsContext';
import useCookies from 'hooks/useCookies';
import PlatformManagementRegionSelectionForm, {
  IPlatformManagementRegionSelectionForm,
} from 'containers/PlatformManagementRegionSelectionForm';

interface PlatformManagementAccountsProps {
  accountType: APPTypes;
  // coming from react.cloneElement
  image?: JSX.Element;
}

const fetchOauthDetails = () => {
  const { account_id, oauth_state, job_id, app } = useCookies();
  return { account_id, job_id, state: oauth_state, app };
};
const PlatformManagementAccounts: React.FC<PlatformManagementAccountsProps> = ({
  accountType,
  image,
}) => {
  const virtuoso = useRef(null);
  const deploybotUrl = process.env.REACT_APP_DEPLOYBOT_API_URL;

  const queryClient = useQueryClient();

  const [accountJobStatus, setAccountJobStatus] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
  const [isAccountSelectionModalOpen, setIsAccountSelectionModalOpen] = useState(false);
  const [isRegionSelectionModalOpen, setIsRegionSelectionModalOpen] = useState(false);
  const [vendorCentralReturnData, setVendorCentralReturnData] = useState(undefined);
  const { selectedClient } = useReportsContext();

  const { data: oauthData } = useQuery(['oauthDetails'], fetchOauthDetails, {
    refetchInterval: 1000,
    cacheTime: 0,
    staleTime: 0,
  });

  const { removePlatformManagementCookies } = useCookies();

  const getOauthAccountsJob = async () => {
    // Checking with job id to check if we should display account selection
    const data = await getOauthAccounts(oauthData);
    setAccountJobStatus(data?.jobStatus);
    return data;
  };
  const { data } = useQuery({
    queryKey: ['platformManagement', oauthData?.state],
    queryFn: () => getOauthAccountsJob(),
    enabled:
      !accountJobStatus &&
      isAccountSelectionModalOpen &&
      !!oauthData?.state &&
      !isRegionSelectionModalOpen,
    refetchInterval: 3000,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const {
    data: addedAccounts,
    isLoading: isLoadingAddedAccounts,
    refetch,
  } = useQuery({
    queryKey: [
      'platformManagement-added-accounts',
      selectedClient?.account_onboarding_state,
      accountType,
    ],
    queryFn: () =>
      getAddedAccounts({ state: selectedClient?.account_onboarding_state, app: accountType }),
    enabled: !!selectedClient?.account_onboarding_state,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (
      (oauthData?.app === 'AMZ-SELLINGPARTNER' ||
        oauthData?.app === 'AMZ-SELLINGPARTNER-SELLER') &&
      oauthData?.state &&
      isRegionSelectionModalOpen
    ) {
      setVendorCentralReturnData({
        account_id: oauthData?.account_id,
        state: oauthData?.state,
        app: oauthData?.app,
      });
      refetch();
    } else if (
      !isRegionSelectionModalOpen &&
      oauthData?.job_id !== 'null' &&
      oauthData?.job_id
    ) {
      setIsAccountSelectionModalOpen(!!oauthData?.state);
    }
  }, [JSON.stringify(oauthData), isRegionSelectionModalOpen]);

  const dynamicDetails = useMemo(() => {
    switch (accountType) {
      case 'AMZ-AD':
        return { url: `${deploybotUrl}oauth/amz-ad`, appName: 'Sponsored Ads' };
      case 'AMZ-SELLINGPARTNER':
        return {
          url: `${deploybotUrl}oauth/sellingpartner`,
          appName: 'Amazon Vendor Central',
        };
      case 'AMZ-SELLINGPARTNER-SELLER':
        return {
          url: `${deploybotUrl}oauth/seller-central`,
          appName: 'Amazon Seller Central',
        };
      case 'DSP':
        return { url: `${deploybotUrl}oauth/dsp`, appName: 'Amazon DSP' };
      case 'AMC':
        return { url: `${deploybotUrl}oauth/amz-amc`, appName: 'Amazon Marketing Cloud' };

      case 'CRITEO':
        return { url: `${deploybotUrl}oauth/criteo`, appName: 'Criteo' };
      default:
        break;
    }
  }, [accountType]);

  const columns: Columns<Account>[] = [
    {
      title: 'Country',
      className: 'w-[20%]',
      cell: (_row) => <div>{_row.country}</div>,
    },
    {
      title: 'Account Name',
      className: 'w-[25%]',
      cell: (row) => <div>{row.account_name}</div>,
    },
    {
      title: 'Account ID',
      className: 'w-[25%]',

      cell: (row) => <div>{row.account_id}</div>,
    },
    {
      title: 'selected',
      cell: (row) => <div>{row.selected}</div>,
      omit: true,
    },
    {
      title: (
        <div className="flex items-center">
          Entity ID
          <MultiToolTip
            id="colors_tooltip"
            place="top"
            className="bg-primary"
            hoverComponent={<InformationIcon className="ms-1 h-5 w-5" />}
          >
            <div className="normal-case font-worksans_semibold text-base">Need Help ?</div>
            <div className="normal-case my-4">
              Refer to the screenshot for a guide on locating your Entity ID.
            </div>
            <img
              src={EntityIDGuide}
              alt="catapult_mockup_screenshot"
              className="object-contain"
            />
          </MultiToolTip>
        </div>
      ),
      omit: accountType !== 'DSP' && accountType !== 'AMZ-AD',
      className: 'w-[25%]',
      cell: (row) => <div>{row?.account_info?.id || row?.account_info?.profile?.id}</div>,
    },
    {
      title: 'Status',
      className: 'w-[7%] ',
      cell: (_row) => (
        <div className="py-1 px-[0.1rem]">
          <Badge state={_row.isValid} />
        </div>
      ),
    },
  ];

  const onCloseModal = () => {
    removePlatformManagementCookies();
    setIsAccountSelectionModalOpen(false);
    setAccountJobStatus(false);
    setSelectedAccounts([]);
  };

  const onSelectedRowsChange = (rows: number[]) => {
    setSelectedAccounts(rows?.map((row) => data?.accounts?.[row]));
  };

  const addAccountsMutation = useMutation({
    mutationFn: async ({ accounts, app: _app, state }: AddAccountsBody) =>
      onAddAccounts({ accounts, app: _app, state }),

    onSettled: (_data, error) => {
      if (!error) {
        queryClient.setQueryData(
          [
            'platformManagement-added-accounts',
            selectedClient?.account_onboarding_state,
            accountType,
          ],
          _data,
        );
      }
    },
  });

  const onSubmitAccounts = async () => {
    await addAccountsMutation.mutateAsync({
      accounts: selectedAccounts?.map((el) => ({ accountId: el.account_id })),
      app: accountType,
      state: selectedClient?.account_onboarding_state,
    });
    setIsAccountSelectionModalOpen(false);
    setAccountJobStatus(false);
    removePlatformManagementCookies();
    setSelectedAccounts([]);
  };

  const onAddSellerCentralAccount = ({ region }: IPlatformManagementRegionSelectionForm) => {
    const url = `${dynamicDetails?.url}?external_apikey=${process.env.REACT_APP_EXTERNAL_API_TOKEN}&state=${selectedClient?.account_onboarding_state}&region=${region.value}`;
    const width = 800;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const windowFeatures = `height=${height},width=${width},top=${top},left=${left},resizable,scrollbars,status`;

    const newWindow = window.open(url, '_blank', windowFeatures);

    setTimeout(() => {
      if (newWindow) {
        newWindow.resizeTo(width, height);
        newWindow.moveTo(left, top);
      }
    }, 100);
  };

  const onCancelAddVendorCentralAccount = () => {
    setIsRegionSelectionModalOpen(false);
    removePlatformManagementCookies();
    setVendorCentralReturnData(undefined);
  };
  const AddButton: React.FC = () => {
    const handleClick = () => {
      if (
        accountType === 'AMZ-SELLINGPARTNER' ||
        accountType === 'AMZ-SELLINGPARTNER-SELLER'
      ) {
        setIsRegionSelectionModalOpen(true);
      } else {
        const url = `${dynamicDetails?.url}?external_apikey=${process.env.REACT_APP_EXTERNAL_API_TOKEN}&state=${selectedClient?.account_onboarding_state}`;
        const width = 800;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        const windowFeatures = `height=${height},width=${width},top=${top},left=${left},resizable,scrollbars,status`;

        const newWindow = window.open(url, '_blank', windowFeatures);

        setTimeout(() => {
          if (newWindow) {
            newWindow.resizeTo(width, height);
            newWindow.moveTo(left, top);
          }
        }, 100);
      }
    };

    return (
      <Button
        containerStyles="w-72"
        type="submit"
        btnType="primary"
        btnSize="md"
        main="Add Account"
        onClick={handleClick}
      />
    );
  };
  return (
    <div>
      <div className="flex items-center">
        {addedAccounts?.accounts?.length !== 0 && !isLoadingAddedAccounts && (
          <div className="w-[25%] h-30 ">
            <AddButton />
          </div>
        )}
        ´
        <div
          className={`${
            addedAccounts?.accounts?.length !== 0 && !isLoadingAddedAccounts
              ? 'w-[72%]'
              : ' w-[100%]'
          } :  mt-2 `}
        >
          <VirtualizedDataTable
            ref={virtuoso}
            columns={columns}
            isLoading={isLoadingAddedAccounts}
            emptyStateComponent={
              <div className="flex flex-col items-center justify-center">
                <div className="font-worksans_regular text-[16px] text-gray-500 text-center mb-10">
                  No Accounts added yet, start adding accounts now.
                </div>
                <AddButton />
              </div>
            }
            data={addedAccounts?.accounts || []}
            className="mt-0 h-[23rem]"
          />
        </div>
        <Modal
          isOpen={isAccountSelectionModalOpen}
          containerStyleOrSize="w-[80vw] h-[81vh]  p--0"
          closeModal={onCloseModal}
          title={`Select ${dynamicDetails?.appName} Accounts`}
        >
          <div>
            <VirtualizedDataTable
              ref={virtuoso}
              columns={columns}
              data={data?.accounts}
              className="mt-2  h-[60vh]"
              selectableRows
              onSelectedRowsChange={onSelectedRowsChange}
              isLoading={!accountJobStatus}
            />
            {!!selectedAccounts?.length && (
              <div className="mt-4 float-end">
                <Button
                  containerStyles="w-72"
                  type="submit"
                  btnType="primary"
                  btnSize="md"
                  main={`Add Selected (${selectedAccounts?.length})`}
                  loading={addAccountsMutation.isLoading}
                  onClick={onSubmitAccounts}
                />
              </div>
            )}
          </div>
        </Modal>
        <Modal
          isOpen={isRegionSelectionModalOpen}
          titleStyle="flex justify-center items-center text-[25px] leading-[40px] font-bold"
          containerStyleOrSize="w-[30vw] h-[57vh] p-0"
          closeModal={onCancelAddVendorCentralAccount}
          title="Add Account"
        >
          {vendorCentralReturnData?.account_id ? (
            <div>
              <div className="flex justify-center items-center h-[40vh]">
                <div className="text-center">
                  <img
                    src={AccountAddedIcon}
                    alt="Amazon Vendor Central"
                    className="mx-auto"
                  />
                  <div className="mt-4 text-[22px] font-sharpsans_semi_bold">
                    Account Authenticated!
                  </div>
                  <div className="text-[14px] text-gray-500 mt-2">{`Account ID: ${vendorCentralReturnData.account_id}`}</div>
                  <Button
                    containerStyles="w-72 mt-10"
                    type="submit"
                    btnType="primary"
                    btnSize="md"
                    main="Finish"
                    onClick={onCancelAddVendorCentralAccount}
                  />
                </div>
              </div>
            </div>
          ) : (
            <PlatformManagementRegionSelectionForm
              onSubmit={onAddSellerCentralAccount}
              image={image}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default PlatformManagementAccounts;
