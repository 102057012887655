import * as React from 'react';

const Criteo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 300 62"
    xmlSpace="preserve"
    width="5em"
    height="3.2em"
    {...props}
  >
    <style type="text/css" id="style33">
      {'.prefix__st0{fill:#fe5000}'}
    </style>
    <g id="prefix__Layer_2_1_" transform="matrix(.28066 0 0 .28066 -60.103 -57.01)">
      <g id="prefix__Layer_1-2">
        <path
          className="prefix__st0"
          d="M609.1 401.4l-35.8-53.2c-2.7-4.1-1.7-9.6 2.4-12.3h.1c16.4-11 26.7-30.7 26.7-53.4 0-38.9-32.5-69.6-72.6-69.6h-86.1c-3.3 0-5.9 2.6-5.9 5.9v37.7c0 1.6 1.2 2.8 2.8 2.9 29.1 1.6 52.6 26.7 52.6 55.4 0 28.9-23.7 53.5-52.6 55.1-1.5.1-2.8 1.4-2.8 2.9v36.6c0 3.3 2.6 5.9 5.9 5.9h50c3.3 0 5.9-2.6 5.9-5.9v-17.6c0-12.5 10.8-15.6 19.1-5.8l21 26.3c1.9 1.9 4.5 3 7.1 3h54.8c4.9 0 8.9-3.9 8.9-8.8.1-1.9-.5-3.6-1.5-5.1z"
          id="prefix__path35"
          fill="#fe5000"
        />
        <path
          className="prefix__st0"
          d="M636.6 212.7H680c3.3 0 5.9 2.6 5.9 5.9v190.6c0 3.3-2.6 5.9-5.9 5.9h-43.4c-3.3 0-5.9-2.6-5.9-5.9V218.6c0-3.2 2.6-5.9 5.9-5.9z"
          id="prefix__path37"
          fill="#fe5000"
        />
        <path
          className="prefix__st0"
          d="M756.3 262.7h-45.7c-3.2 0-5.9-2.6-5.9-5.8v-38.2c0-3.3 2.6-5.9 5.9-5.9H862c3.3 0 5.9 2.6 5.9 5.9v38.2c0 3.3-2.6 5.9-5.9 5.9h-44.6c-1.6 0-2.9 1.3-2.9 3v143.5c0 3.3-2.6 5.9-5.9 5.9h-43.4c-3.3 0-5.9-2.6-5.9-5.9V265.6c-.1-1.6-1.4-2.9-3-2.9z"
          id="prefix__path39"
          fill="#fe5000"
        />
        <path
          className="prefix__st0"
          d="M893.4 212.7h137.5c3.3 0 5.9 2.6 5.9 5.9v37.9c0 3.3-2.6 5.9-5.9 5.9h-85.2c-1.7 0-3 1.3-3 3v22c0 1.6 1.3 2.9 2.9 2.9H1024.8c3.3 0 5.9 2.6 5.9 5.9v33.5c0 3.3-2.6 5.9-5.9 5.9h-79.1c-1.6 0-3 1.3-3 2.9v24.9c0 1.6 1.4 2.9 3 2.9h87.5c3.3 0 5.9 2.6 5.9 5.9v37c0 3.3-2.6 5.9-5.9 5.9H893.4c-3.3 0-5.9-2.6-5.9-5.9V218.6c0-3.2 2.6-5.9 5.9-5.9z"
          id="prefix__path41"
          fill="#fe5000"
        />
        <path
          className="prefix__st0"
          d="M1165 209.8c-61.8 0-111.5 47-111.5 104.8s49.7 104.5 111.5 104.5c62.1 0 111.8-46.8 111.8-104.5-.1-57.7-49.7-104.8-111.8-104.8zm0 160c-30.4.1-55.1-24.5-55.2-54.8v-.3c0-29.9 24.7-55.5 55.2-55.5 30.5 0 55.5 25.8 55.5 55.5 0 29.8-25.2 55.1-55.5 55.1z"
          id="prefix__path43"
          fill="#fe5000"
        />
        <path
          className="prefix__st0"
          d="M333.6 209.8c31.5 0 59.7 13.4 79.8 34.9 2.2 2.4 2.1 6.1-.3 8.3l-.2.2-28.6 23.9c-2.3 1.9-5.7 1.8-7.9-.3-11.4-11.2-26.8-17.5-42.8-17.6-30.5 0-55.2 23.2-55.2 54.9s24.7 54.6 55.2 54.6c16-.1 31.3-6.4 42.8-17.6 2.2-2.1 5.6-2.3 7.9-.4l28.6 24c2.5 2.1 2.8 5.8.8 8.3-.1.1-.1.2-.2.2-20.1 21.4-48.3 34.9-79.8 34.9-61 0-110.4-43.8-110.4-103.9 0-60.1 49.3-104.4 110.3-104.4z"
          id="prefix__path45"
          fill="#fe5000"
        />
      </g>
    </g>
  </svg>
);

const MemoCriteo = React.memo(Criteo);
export default MemoCriteo;
