/* eslint-disable @typescript-eslint/no-explicit-any */
import { Columns, DataTableProps } from './Columns';
import { TableVirtuoso } from 'react-virtuoso';
import DataTableRow from './DataTableRow';
import { forwardRef, useState } from 'react';
import DataTableCheckBox from './DataTableCheckBox';
import Spinner from 'components/Spinner';

const VirtualizedDataTable = forwardRef<HTMLDivElement, DataTableProps<any>>(
  (
    {
      data,
      columns,
      rowStyle,
      className,
      selectableRows,
      onSelectedRowsChange,
      isLoading,
      emptyStateComponent,
    }: DataTableProps<any>,
    ref,
  ) => {
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    return (
      <div>
        <div>
          {!isLoading ? (
            <div>
              {data?.length ? (
                <div
                  className={`mt-8 w-full rounded-3xl border border-table_border${className}`}
                >
                  <TableVirtuoso
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ref={ref}
                    data={data}
                    fixedHeaderContent={() => (
                      <tr className="bg-white">
                        {selectableRows && (
                          <th
                            scope="col"
                            className="px-3 py-5  text-sm text-gray_text "
                            key="select"
                          >
                            <div className="flex justify-center">
                              <DataTableCheckBox
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setSelectedRows(data.map((_, index) => index));
                                    onSelectedRowsChange?.(data.map((_, index) => index));
                                  } else {
                                    setSelectedRows([]);
                                    onSelectedRowsChange([]);
                                  }
                                }}
                                checked={selectedRows.length === data.length}
                              />
                            </div>
                          </th>
                        )}
                        {columns
                          .filter((el) => !el.omit)
                          .map((col) => (
                            <th
                              scope="col"
                              className="px-3 py-5 text-left text-sm text-gray_text "
                              key={`${col.title as string}`}
                            >
                              <div className="font-worksans_regular text-[12px] leading-[16px] uppercase">
                                {col.title}
                              </div>
                            </th>
                          ))}
                      </tr>
                    )}
                    itemContent={(index, row) => (
                      <DataTableRow
                        key={index.toString()}
                        rowStyle={rowStyle?.(row, index)}
                        item={row}
                        itemIndex={index}
                        setSelectedRows={setSelectedRows}
                        selectableRows={selectableRows}
                        selectedRows={selectedRows}
                        columns={columns as Columns<Record<string | number, unknown>>[]}
                        onSelectedRowsChange={onSelectedRowsChange}
                      />
                    )}
                  />
                </div>
              ) : (
                <div
                  className={`text-gray-700 font-medium flex items-center  justify-center ${className}`}
                >
                  {emptyStateComponent || ' The list is empty'}
                </div>
              )}
            </div>
          ) : (
            <Spinner className={`flex items-center justify-center ${className}`} />
          )}
        </div>
      </div>
    );
  },
);

export default VirtualizedDataTable;
