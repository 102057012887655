/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';

interface CarouselProps {
  items: {
    imgUrl: JSX.Element;
    title: string;
    accountsCount: number;
    updatesCount: number;
    elementContainer?: JSX.Element;
  }[];
}
const Carousel: React.FC<CarouselProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleSlideChange = (props) => {
    if (props.clickedIndex === undefined) return;
    props.slideTo(props.clickedIndex);
    setActiveIndex(props.clickedIndex);
  };

  return (
    <div>
      <Swiper
        onClick={handleSlideChange}
        slidesPerView={5.5}
        navigation
        spaceBetween={30}
        modules={[Navigation]}
      >
        {items.map((element, index) => (
          <SwiperSlide
            className={index === activeIndex ? 'bg-secondary' : 'bg-very_light_gray'}
            key={element.title}
          >
            <div>
              {element.imgUrl}
              <div className="text-[15px] font-sharpsans_semi_bold">{element.title}</div>
              <div className="text-14px font-worksans_regular">
                <span>
                  {`${element.accountsCount} Accounts ${element.updatesCount > 0 ? ',' : ''}`}
                </span>
                {element.updatesCount > 0 && (
                  <span className="text-red-600">{` ${element.updatesCount} Updates`}</span>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {items?.[activeIndex]?.elementContainer
        ? React.cloneElement(items?.[activeIndex].elementContainer, {
            image: items?.[activeIndex]?.imgUrl,
          })
        : null}
    </div>
  );
};

export default Carousel;
