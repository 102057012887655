export const checkIfSectionPage = (pathname: string) =>
  pathname.startsWith('/portal') && pathname.split('/').length >= 3;

export const getPrimaryEmail = (email) => {
  let email_;
  if (email?.includes('#EXT#')) {
    email_ = email.split('#EXT#')[0].replace('_', '@');
  } else {
    email_ = email;
  }
  return email_;
};
