import Carousel from 'components/Carousel';
import PageTitle from 'components/PageTitle';
import PlatformManagementAccounts from 'containers/PlatformManagementAccounts';
import useCookies from 'hooks/useCookies';
import { useEffect } from 'react';
import VendorCentral from '../../assets/images/accountOnboarding/vendor_central.svg';
import SellerCentral from '../../assets/images/accountOnboarding/seller_central.svg';
import SponsoredAds from '../../assets/images/accountOnboarding/amazon_ads.svg';
import Dsp from '../../assets/images/accountOnboarding/amazon_dsp.svg';
import Amc from '../../assets/images/accountOnboarding/amazon_marketing_cloud.webp';
import Criteo from '../../assets/images/accountOnboarding/Criteo';
import { fetchActiveModulesAndAccountStats } from 'api/requests/deploybot';
import { useReportsContext } from 'context/ReportsContext';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'components/Spinner';
import { APPTypes } from 'Models/IPlatformManagement';

const PlatformManagementPage = () => {
  const { removePlatformManagementCookies } = useCookies();

  useEffect(
    () => () => {
      removePlatformManagementCookies();
    },
    [removePlatformManagementCookies],
  );

  const { selectedClient } = useReportsContext();

  const { data, isLoading } = useQuery({
    queryKey: [
      'get-active-modules-and-accounts-stats',
      selectedClient?.account_onboarding_state,
    ],
    queryFn: () => fetchActiveModulesAndAccountStats({ clientId: selectedClient._id }),
    enabled: !!selectedClient?.account_onboarding_state,
    refetchOnWindowFocus: false,
  });

  const renderPlatformIcon = (app: APPTypes) => {
    switch (app) {
      case 'AMZ-SELLINGPARTNER':
        return (
          <img
            src={VendorCentral}
            alt="Amazon Vendor Central"
            className="h-[60px] w-[130px] flex justify-center items-center"
          />
        );
      case 'AMZ-SELLINGPARTNER-SELLER':
        return (
          <img
            src={SellerCentral}
            alt="Amazon Seller Central"
            className="h-[60px] w-[120px]"
          />
        );

      case 'AMZ-AD':
        return (
          <img
            src={SponsoredAds}
            alt="Amazon Ads Accounts"
            className="object-cover h-[60px] w-[160px]"
          />
        );
      case 'DSP':
        return <img src={Dsp} alt="Amazon DSP" className="h-[35px] w-[100px] mt-5 mb-2" />;
      case 'AMC':
        return (
          <img
            src={Amc}
            alt="Amazon Marketing Cloud"
            className="h-[40px] w-[90px] mt-3 mb-2"
          />
        );
      case 'CRITEO':
        return <Criteo />;
      default:
        return null;
    }
  };
  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <PageTitle
            subtitle="Manage platform integration and onboard new accounts "
            title="Platform Management"
          />
          <div className="mt-10">
            {data && Object.keys(data).length > 0 ? (
              <Carousel
                items={Object.entries(data)?.map(([key, value]) => ({
                  title: value.title,
                  imgUrl: (
                    <div className="flex justify-center items-center">
                      {renderPlatformIcon(key as APPTypes)}
                    </div>
                  ),
                  accountsCount: value.totalAccounts,
                  updatesCount: value.updates,
                  elementContainer: (
                    <PlatformManagementAccounts accountType={key as APPTypes} />
                  ),
                }))}
              />
            ) : (
              <div className="text-gray-700 font-medium flex items-center  justify-center h-[50vh]">
                No active modules found. Please activate a module to add accounts.
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlatformManagementPage;
