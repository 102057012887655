import Button from 'components/Button';
import FormSelect from 'components/Form/FormSelect';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ISelectorOption } from 'types/ISelectorOption';

export interface IPlatformManagementRegionSelectionForm {
  region: ISelectorOption;
}
interface PlatformManagementRegionSelectionFormProps {
  onSubmit: SubmitHandler<IPlatformManagementRegionSelectionForm>;
  image?: JSX.Element;
}
const PlatformManagementRegionSelectionForm: React.FC<
  PlatformManagementRegionSelectionFormProps
> = ({ onSubmit, image }) => {
  const methods = useForm<IPlatformManagementRegionSelectionForm>();

  const { handleSubmit, watch } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSelect
          name="region"
          label="REGION"
          placeholder="select..."
          options={[
            {
              label: 'North America',
              value: 'com',
            },
            {
              label: 'Europe',
              value: 'de',
            },
            {
              label: 'Far East',
              value: 'com.au',
            },
          ]}
        />
        <div className="flex items-center justify-center h-[20vh] mt-10">
          <div className="w-[40%] me-3">{image}</div>
          <div className="text-[12px] text-gray_text">
            Select the appropriate region in Vendor Central to manage your operations
            effectively. Regions correspond to Amazon marketplaces, such as:
            <ul className="text-[12px] font-worksans_regular mt-2">
              <li>North America : Canada, US, Mexico, and Brazil</li>
              <li>
                Europe : Spain, UK, France, Belgium, Netherlands, Germany, Italy, Sweden,
                Poland, Saudi Arabia, Egypt, Turkey, United Arab Emirates, and India
              </li>
              <li>Far East : Singapore, Australia, and Japan</li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center absolute bottom-6 right-0 left-0 ">
          <Button
            containerStyles="w-72"
            type="submit"
            btnType="primary"
            btnSize="md"
            main="Next"
            disabled={watch('region') === undefined}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default PlatformManagementRegionSelectionForm;
