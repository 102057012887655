/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';
import { Columns } from './Columns';
import DataTableCheckBox from './DataTableCheckBox';

type DataTableRowProps<T extends Record<string | number, any>> = {
  item: T;
  columns: Columns<T>[];
  itemIndex: number;
  rowStyle?: string;
  selectableRows?: boolean;
  setSelectedRows?: (rows: number[]) => void;
  selectedRows?: number[];
  onSelectedRowsChange?: (rows: number[]) => void;
};

function DataTableRow<T extends Record<string | number, any>>({
  item,
  columns,
  itemIndex,
  rowStyle,
  selectableRows,
  selectedRows,
  setSelectedRows,
  onSelectedRowsChange,
}: DataTableRowProps<T>) {
  return (
    <>
      {selectableRows && (
        <td
          // eslint-disable-next-line react/no-array-index-key
          key="checkbox"
          className="pl-3 pr-3 text-sm  border-b border-gray-200 py-2 w-[10%]"
        >
          <div className="flex justify-center">
            <DataTableCheckBox
              onChange={(event) => {
                if (event.target.checked) {
                  const _selectedRows = [...selectedRows, itemIndex];
                  setSelectedRows(_selectedRows);
                  onSelectedRowsChange?.(_selectedRows);
                } else {
                  const unselectedRows = selectedRows.filter((row) => row !== itemIndex);
                  setSelectedRows(unselectedRows);
                  onSelectedRowsChange?.(unselectedRows);
                }
              }}
              disabled={item.selected}
              checked={selectedRows.includes(itemIndex)}
            />
          </div>
        </td>
      )}
      {columns
        .filter((el) => !el.omit)
        .map((col, index) => (
          <td
            // eslint-disable-next-line react/no-array-index-key
            key={index.toString()}
            className={`${col.className} ${rowStyle} pl-3 pr-3 text-sm  border-b border-gray-200 py-2`}
            style={{
              verticalAlign: 'middle',
              color: col.textColor?.(item, itemIndex),
            }}
          >
            {col.cell?.(item, itemIndex) ? (
              <div
                className={`overflow-hidden font-worksans_regular text-[16px] leading-[20px] ${
                  col.center ? 'flex justify-center items-center' : ''
                }`}
                style={{ width: col.width }}
              >
                {col.cell?.(item, itemIndex)}
              </div>
            ) : (
              <div className="flex items-center">{item[col.selector || '']}</div>
            )}
          </td>
        ))}
    </>
  );
}

export default memo(DataTableRow);
