/* eslint-disable @typescript-eslint/no-unused-vars */
import Spinner from 'components/Spinner';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const RedirectPage = () => {
  const [searchParams, _setSearchParams] = useSearchParams();

  const query = new URLSearchParams({
    state: searchParams.get('state'),
    job_id: searchParams.get('job_id'),
    account_id: searchParams.get('account_id'),
    app: searchParams.get('app'),
  });

  useEffect(() => {
    if (!query) return;
    Cookies.set('oauth_state', searchParams.get('state'));
    Cookies.set('oauth_job_id', searchParams.get('job_id'));
    Cookies.set('oauth_account_id', searchParams.get('account_id'));
    Cookies.set('oauth_app', searchParams.get('app'));
    setTimeout(() => {
      window.close();
    }, 3000);
  }, [query]);

  return (
    <div>
      <Spinner loadingTitle={<div className="ms-4">Processing...</div>} />
    </div>
  );
};

export default RedirectPage;
