import './styles.css';

interface ISpinner {
  className?: string;
  loadingTitle?: string | JSX.Element;
}

const Spinner: React.FC<ISpinner> = ({ className, loadingTitle }) => (
  <div className={className || 'absolute inset-0 flex items-center justify-center'}>
    <div className="dot-spinner">
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
    </div>
    {loadingTitle}
  </div>
);

export default Spinner;
