import * as React from 'react';

const PlatformManagement = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none" {...props}>
    <path
      strokeLinecap="round"
      strokeWidth={2.1}
      d="M10 29v4.6a2.4 2.4 0 0 0 2.4 2.4h23.2a2.4 2.4 0 0 0 2.4-2.4V29"
    />
    <path
      strokeWidth={2.1}
      d="M27.286 25h3.428c.158 0 .286.18.286.4v3.2c0 .22-.128.4-.286.4h-3.428c-.158 0-.286-.18-.286-.4v-3.2c0-.22.128-.4.286-.4ZM27.286 16h3.428c.158 0 .286.18.286.4v3.2c0 .22-.128.4-.286.4h-3.428c-.158 0-.286-.18-.286-.4v-3.2c0-.22.128-.4.286-.4ZM14.571 12h6.858c.315 0 .571.358.571.8v6.4c0 .442-.256.8-.571.8H14.57c-.315 0-.571-.358-.571-.8v-6.4c0-.442.256-.8.571-.8ZM18.286 25h3.428c.158 0 .286.18.286.4v3.2c0 .22-.128.4-.286.4h-3.428c-.158 0-.286-.18-.286-.4v-3.2c0-.22.128-.4.286-.4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PlatformManagement;
