import React from 'react';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const DataTableCheckBox: React.FC<InputProps> = ({ ...props }) => (
  <input
    type="checkbox"
    className="border-gray-300 accent-primary bg-gray-700 text-primary cursor-pointer"
    {...props}
  />
);

export default DataTableCheckBox;
