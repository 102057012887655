import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';

interface ModalProps {
  title?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  closeModal: () => void;
  withCloseIcon?: boolean;
  titleStyle?: string;
  containerStyleOrSize?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | string;
}

const Modal = ({
  title,
  children,
  isOpen,
  closeModal,
  containerStyleOrSize,
  withCloseIcon = true,
  titleStyle,
}: ModalProps) => {
  const styles = React.useMemo(() => {
    let sizeStyle = '';
    switch (containerStyleOrSize) {
      case 'sm':
        sizeStyle = 'max-w-sm';
        break;
      case 'md':
        sizeStyle = 'max-w-md';
        break;
      case 'lg':
        sizeStyle = 'max-w-lg';
        break;
      case 'xl':
        sizeStyle = 'max-w-xl';
        break;
      case '2xl':
        sizeStyle = 'max-w-2xl';
        break;
      default:
        sizeStyle = containerStyleOrSize || 'max-w-xl';
        break;
    }

    return {
      sizeStyle,
    };
  }, [containerStyleOrSize]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative ${styles.sizeStyle} max-h-[90vh] overflow-y-auto transform rounded-lg bg-white p-6 text-left shadow-xl transition-all flex flex-col gap-5`}
              >
                {title && (
                  <Dialog.Title
                    as="h3"
                    className={titleStyle || 'text-lg leading-6 font-medium text-gray-900'}
                  >
                    {title}
                  </Dialog.Title>
                )}
                <div className="absolute right-0 top-0 hidden 2xl:pr-[48px] xl:pr-[48px] pr-[40px] pt-5 sm:block">
                  <button type="button" onClick={() => closeModal()}>
                    {withCloseIcon && <XMarkIcon className="h-7 w-7" aria-hidden="true" />}
                  </button>
                </div>
                <div>{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
